<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title class="ion-text-center">
          <img src="assets/icon/logo.png" style="height:40px;" alt="xtenso|xsample">
        </ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true" class=" ion-padding-vertical">
      <ion-card
        v-if="$store.state.resources.length == 0"
        color="warning"
        class="ion-text-center"
      >
        <ion-card-header>
          <ion-card-title
            >Aucune ressource n'est encore définie.</ion-card-title
          >
        </ion-card-header>
        <ion-card-content>
          <ion-button color="primary" @click="showAddResource()">
            Créer une ressource.
          </ion-button>
          <p class="ion-padding-vertical">
            Pour commencer votre travail d’échantillonnage, veuillez définir vos
            ressources, puis pour chaque ressource, ses activités.
          </p>
        </ion-card-content>
      </ion-card>

      <h3 class="ion-padding">Proposé par</h3>
      <p class="ion-padding ion-text-center">
        <a href="https://xtenso.com/" target="about:blank"
          ><img class="logo" src="assets/icon/logo_xtenso.png" alt="logo"
        /></a>
      </p>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { showAddResource } from "@/services/modals";

import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/vue";

export default defineComponent({
  name: "Home",
  components: {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
  },
  setup() {
    return {
      showAddResource,
    };
  },
});
</script>

<style scoped>
</style>